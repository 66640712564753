<template>
  <el-dialog
    center
    @close="closeModal()"
    :visible="visible">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <div slot="header">
          <h4 class="card-title">
            {{ title }}
          </h4>
        </div>
          <div class="card-body">
            <ValidationProvider
              vid="name"
              rules="required"
              name="The name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Name"
                        name="name"
                        @keyup="generateURL"
                        fou
                        v-model="formData.name">
              </fg-input>
            </ValidationProvider>
            <ValidationProvider
              vid="url"
              rules="required"
              name="The url"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="URL"
                        name="url"
                        fou
                        v-model="formData.url">
              </fg-input>
            </ValidationProvider>
          </div>
          <span slot="footer" class="dialog-footer">
                  <button class="btn btn-danger" @click="closeModal()">Cancel</button>&nbsp;
                  <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                                nativeType="submit"
                                type="info" wide>Submit
                  </l-button>
        </span>
      </ValidationObserver>
    </div>
  </el-dialog>
</template>

<script>
import {Dialog} from 'element-ui'


export default {
  name: 'quick-adding-modal',
  components: {
    [Dialog.name]: Dialog,
  },

  props: {
    visible: {
      type: Boolean,
      default: 0
    },
    url: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
  },
  data() {

    return {
      entityNotFoundError: false,
      submitting: false,
      formData: {
        name: "",
        url: "",
        is_active: true,
        lang: 'ar',
      },
    }
  },

  created(){
    const path = window.location.href;
    this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
  },

  mounted() {
  },
  methods: {
    closeModal() {
      this.formData.name = '';
      this.formData.url = '';
      this.$emit('close');
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      request = this.axios.post(this.url, this.formData);
      successMessage = "Added Successfully";

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.closeModal();
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    generateURL() {
      let newUrl = '';
      if (this.formData.name) {
        newUrl = this.formData.name.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }

      this.formData.url = newUrl;
    }
  }
}
</script>
<style>
</style>
